<m-overlay
  id="application-form-management__application-form-export-overlay"
  :open="isOverlayOpen"
  :waiting="isLoadingStateActive"
  @portal-after-close="afterOverlayClose"
>
  <template #trigger>
    <m-button @click="onTriggerButtonClick">
      {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.trigger.label') }}
    </m-button>
  </template>
  <template #header>
    <h2>
      {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.overlay_title') }}
    </h2>
  </template>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="showSuccessMessage">
      <m-message
        class="my-auto"
        :skin="MMessageSkin.Page"
        :title="$i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.message.success.title')"
      />
    </template>
    <template v-else>
      <m-form :form-group="formGroup">
        <m-message
          class="mb-6"
          :state="MMessageState.Information"
        >
          <p class="m-0">
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.message.informations.content') }}
          </p>
        </m-message>
        <m-multi-select
          v-model="selectedStatus"
          :options="statusSelectOptions"
          v-m-control="statusMultiSelect"
          :required-marker="true"
          max-width="none"
          :link-select-all="true"
          :defaultChipCharsTrunk="100"
          :label="$i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.field.status.label')"
          :placeholder="$i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.field.status.placeholder')"
          :label-up="true"
          :error="statusMultiSelect.hasError()"
          :error-message="statusMultiSelect.errorMessage"
        >
          <template #items="{ item }">
            {{ mixin_applicationForm_computeStatusLabel(item) }}
          </template>
          <template #chips="{ item }">
            {{ mixin_applicationForm_computeStatusLabel(item) }}
          </template>
        </m-multi-select>
      </m-form>
    </template>
  </transition>
  <template #footer>
    <transition
      name="fade-fast"
      mode="out-in"
    >
      <template v-if="!showSuccessMessage">
        <div>
          <m-button
            :waiting="isLoadingStateActive"
            class="mr-4"
            @click="onSaveButtonClick"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.button.save.label') }}
          </m-button>
          <m-button
            :skin="MButtonSkin.Secondary"
            @click="closeOverlay"
          >
            {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.button.cancel.label') }}
          </m-button>
        </div>
      </template>
      <template v-else>
        <m-button
          :skin="MButtonSkin.Secondary"
          @click="closeOverlay"
        >
          {{ $i18next.t('modules.application-form-management.application-form.ApplicationFormExportOverlay.button.close.label') }}
        </m-button>
      </template>
    </transition>
  </template>
  <a
    :ref="REF_DOWNLOAD_LINK"
    class="display--none"
  />
</m-overlay>