import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import WithRender from './AdminApplicationForm.html'

import { 
  MButton,
  MButtonSkin,
  MDialog,
  MDialogState,
  MDialogWidth,
  MIcon,
  MMessage,
  MMessageState,
  MPanel,
  MSpinner,
  MToast,
  MToastDuration,
  MToastPosition,
  MToastState,
  MToastTimeout,
} from '@ulaval/modul-components'

import Breadcrumbs from '@/modules/global/breadcrumbs/Breadcrumbs'
import FormElements from '@/modules/form-management/form-element/FormElements'

import ApplicationFormService, { IFetchApplicationFormResponse } from '../ApplicationForm.service'
import { IApplicationFormModel } from '../ApplicationForm.model'
import { IFormModel } from '@/modules/form-management/Form.model'
import { IInternalFormTemplateModel } from '@/modules/form-template-management/FormTemplate.model'
import ApplicationFormMixin from '../ApplicationForm.mixin'
import { IFormAnswerForm } from '@/modules/form-management/Form.service'
import { AxiosError } from 'axios'

const FORM_ELEMENTS_REF = 'form-elements'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    FormElements,
    MButton,
    MDialog,
    MIcon,
    MMessage,
    MPanel,
    MSpinner,
    MToast
  }
})
export default class AdminApplicationForm extends mixins(ApplicationFormService, ApplicationFormMixin) {
  private applicationForm: IApplicationFormModel | null = null
  private form: IFormModel | null = null
  private internalFormTemplate: IInternalFormTemplateModel | null = null
  private isLoadingStateActive: boolean = true  
  private isSubmitConfirmationDialogActive: boolean = false
  private isSubmitSuccessToastOpen: boolean = false
  private isSubmitErrorToastOpen: boolean = false
  private isValidationErrorToastOpen: boolean = false
  private isDownloadAllFormAnswerFilesLoadingStateActive: boolean = false

  private readonly FORM_ELEMENTS_REF: string = FORM_ELEMENTS_REF
  private readonly MMessageState: object = MMessageState
  private readonly MDialogState: object = MDialogState
  private readonly MDialogWidth: object = MDialogWidth
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly MToastTimeout: object = MToastTimeout
    private readonly MButtonSkin: object = MButtonSkin

  @Ref(FORM_ELEMENTS_REF)
  private formElementsComponent!: FormElements

  private get submitDialogState(): MDialogState {
    if (this.isSubmitErrorToastOpen) {
      return MDialogState.Error
    } else if (this.isSubmitSuccessToastOpen) {
      return MDialogState.Confirmation
    }

    return MDialogState.Warning
  }

  @Watch('$route.params.idApplicationForm', { immediate: true })
  private onRouteParamsIdChange(value: string | null) {
    if (value) {
      this.loadApplicationForm(value)
    }
  }

  private loadApplicationForm(id: string, isBackgroundRefresh: boolean = false): void {
    this.isLoadingStateActive = !isBackgroundRefresh

    this.service_applicationFormService_fetchAdminApplicationForm(id)
      .then((response: IFetchApplicationFormResponse) => {
        this.applicationForm = response.application_form || null
        this.form = response.form || null
        this.internalFormTemplate = response.internal_form_template || null
      }).catch(() => {
        this.applicationForm = null
        this.form = null
        this.internalFormTemplate = null
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }

  private async onSubmitButtonClick(): Promise<void> {
    const answers: Array<IFormAnswerForm> | null = await this.formElementsComponent.getElementAnswers(false)

    if (answers) {
      this.submitApplicationForm(answers)
    }
  }

  private submitApplicationForm(answers: Array<IFormAnswerForm>): void {
    if (this.applicationForm) {
      this.isLoadingStateActive = true

      this.service_applicationFormService_saveApplicationFormAdmin(this.applicationForm.id, answers)
        .then(() => {
          this.loadApplicationForm(this.$route.params.idApplicationForm)

          this.isSubmitSuccessToastOpen = true
          this.isSubmitErrorToastOpen = false
        }).catch(() => {
          this.isSubmitSuccessToastOpen = false
          this.isSubmitErrorToastOpen = true
          this.isLoadingStateActive = false
        }).finally(() => {
          setTimeout(() => {
            this.isSubmitSuccessToastOpen = false
          }, MToastDuration.DesktopXShort)
          setTimeout(() => {
            this.isSubmitErrorToastOpen = false
          }, MToastDuration.DesktopXShort)
        })
    }
  }

  private async displaySubmitConfirmationDialog(): Promise<void> {
    this.isSubmitConfirmationDialogActive = true
  }

  private hideSubmitConfirmationDialog(): void {
    this.isSubmitConfirmationDialogActive = false
  }

  private async downloadAllFormAnswerFiles(): Promise<void> {
    this.isDownloadAllFormAnswerFilesLoadingStateActive = true

    await this.mixin_applicationForm_downloadAllFormAnswerFiles(this.form || undefined)

    this.isDownloadAllFormAnswerFilesLoadingStateActive = false
  }
}
