import Component, { mixins } from 'vue-class-component'

import { ApplicationFormStatus, IApplicationFormModel } from './ApplicationForm.model';
import { IFormAnswerModel, IFormModel } from '../form-management/Form.model';
import { IFileUploadModel } from '../global/file/File.model';
import axios from 'axios';

@Component
export default class ApplicationFormMixin extends mixins() {
  protected mixin_applicationForm_computeStatusColor(applicationForm: IApplicationFormModel): string {
    switch (applicationForm.status) {
      case ApplicationFormStatus.INACTIVE:
        return '#d7d7d7'
      case ApplicationFormStatus.IN_PROGRESS:
        return '#ffc103'
      case ApplicationFormStatus.OVERDUE:
        return '#e30513'
      case ApplicationFormStatus.SUBMITTED:
        return '#00c77f'
      default:
        return 'grey'
    }
  }

  protected mixin_applicationForm_computeStatusIcon(applicationForm: IApplicationFormModel): string | null {
    switch (applicationForm.status) {
      case ApplicationFormStatus.INACTIVE:
        return 'pd-svg__circle-block'
      case ApplicationFormStatus.IN_PROGRESS:
        return 'pd-svg__user-wait'
      case ApplicationFormStatus.OVERDUE:
        return 'm-svg__error'
      case ApplicationFormStatus.SUBMITTED:
        return 'm-svg__confirmation'
      default:
        return null
    }
  }

  protected mixin_applicationForm_computeStatusIconStyles(applicationForm: IApplicationFormModel): object {
    return {
      'color': this.mixin_applicationForm_computeStatusColor(applicationForm)
    }
  }

  protected mixin_applicationForm_isApplicationFormInactive(applicationForm: IApplicationFormModel): boolean {
    return applicationForm.status === ApplicationFormStatus.INACTIVE
  }

  protected mixin_applicationForm_computeStatusLabel(status: ApplicationFormStatus | string): string | null {
    return this.$i18next.t(`modules.application-form-management.ApplicationFormMixin.status.${status}`)
  }

  protected mixin_applicationForm_hasFormAnswerFiles(form?: IFormModel): boolean {
    if (!form || !form.form_answers) {
      return false
    }

    return form.form_answers.some((formAnswer: IFormAnswerModel) => formAnswer.form_answer_files.length > 0)
  }

  protected async mixin_applicationForm_downloadAllFormAnswerFiles(form?: IFormModel): Promise<void> {
    if (form && form.form_answers) {
      for (const formAnswer of form.form_answers) {
        for (const formAnswerFile of formAnswer.form_answer_files) {
          await this.mixin_applicationForm_internalDownloadFile({ server_file: formAnswerFile})
        }
      }
    }
  }

  private async mixin_applicationForm_internalDownloadFile(file: IFileUploadModel<any>): Promise<void> {
    if (file.file) {
      this.mixin_applicationForm_internalOpenFile(URL.createObjectURL(file.file as any), file.file.name)
    } else if (file.server_file && file.server_file.download_endpoint) {
      const response = await axios.get(file.server_file.download_endpoint, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream'
        }
      })

      if (file.server_file) {
        this.mixin_applicationForm_internalOpenFile(URL.createObjectURL(new Blob([response.data], { type: file.server_file.file_format })), file.server_file.file_name)
      }
    }
  }

  private mixin_applicationForm_internalOpenFile(url: string, fileName: string): void {
    const downloadLinkElement = document.createElement('a')

    downloadLinkElement.style.visibility = 'hidden'
    downloadLinkElement.setAttribute('href', url)
    downloadLinkElement.setAttribute('download', fileName)
    downloadLinkElement.click()

    downloadLinkElement.removeAttribute('link')
    downloadLinkElement.removeAttribute('download')
  }
}