import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './EvaluatorApplicationFormAssignment.html'

import { 
  MButton,
  MButtonSkin,
  MMessage,
  MMessageState,
  MPanel,
  MSpinner,
} from '@ulaval/modul-components'

import Breadcrumbs from '@/modules/global/breadcrumbs/Breadcrumbs'
import FormElements from '@/modules/form-management/form-element/FormElements'

import { IFormAnswerModel } from '@/modules/form-management/Form.model'
import { IInternalFormTemplateModel } from '@/modules/form-template-management/FormTemplate.model'
import EvaluatorApplicationFormAssignmentService, { IFetchEvaluatorApplicationFormAssignmentApplicationFormResponse } from '../EvaluatorApplicationFormAssignment.service'
import { IEvaluatorApplicationFormAssignmentModel } from '../EvaluatorApplicationFormAssignment.model'
import { IEvaluationCardModel } from '@/modules/category-management/evaluation-card-management/EvaluationCard.model'
import ROUTES from '@/router/ROUTES'
import ApplicationFormMixin from '@/modules/application-form-management/ApplicationForm.mixin'

const REF_EVALUATION_CARD_DOWNLOAD_LINK: string = 'ref-evaluation-card-download-link'

@WithRender
@Component({
  components: {
    Breadcrumbs,
    FormElements,
    MButton,
    MMessage,
    MPanel,
    MSpinner
  }
})
export default class EvaluatorApplicationFormAssignment extends mixins(EvaluatorApplicationFormAssignmentService, ApplicationFormMixin) {
  private evaluatorApplicationFormAssignment: IEvaluatorApplicationFormAssignmentModel | null = null
  private formAnswers: Array<IFormAnswerModel> = []
  private internalFormTemplate: IInternalFormTemplateModel | null = null
  private isLoadingStateActive: boolean = true
  private isDownloadAllFormAnswerFilesLoadingStateActive: boolean = false

  private readonly REF_EVALUATION_CARD_DOWNLOAD_LINK: string = REF_EVALUATION_CARD_DOWNLOAD_LINK
  private readonly MMessageState: object = MMessageState
  private readonly MButtonSkin: object = MButtonSkin

  @Ref(REF_EVALUATION_CARD_DOWNLOAD_LINK)
  protected downloadLinkHtmlElement!: HTMLElement

  private get evaluationCard(): IEvaluationCardModel | null {
    if (
      !this.internalFormTemplate ||
      !this.internalFormTemplate.category.evaluation_card
    ) {
      return null
    }

    return this.internalFormTemplate.category.evaluation_card
  }

  private get mockForm(): any {
    return {
      form_answers: this.formAnswers
    }
  }

  @Watch('$route.params.idEvaluatorApplicationFormAssignment', { immediate: true })
  private onRouteParamsIdChange(value: string | null) {
    if (value) {
      this.loadEvaluatorApplicationFormAssignmentApplicationForm(value)
    }
  }

  private loadEvaluatorApplicationFormAssignmentApplicationForm(id: string): void {
    this.isLoadingStateActive = true

    this.service_evaluatorApplicationFormAssignmentService_fetchApplicationForm(id)
      .then((response: IFetchEvaluatorApplicationFormAssignmentApplicationFormResponse) => {
        this.evaluatorApplicationFormAssignment = response.evaluator_application_form_assignment || null
        this.formAnswers = response.form_answers || []
        this.internalFormTemplate = response.internal_form_template || null
      }).catch((error: AxiosError) => {
        this.isLoadingStateActive = false
        this.mixin_router_redirectRequestError(error)
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }

  private downloadEvaluationCard(): void {
    if (this.evaluationCard && this.evaluationCard.download_endpoint) {
      this.$axios.get(this.evaluationCard.download_endpoint, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream'
        }
      }).then((res: any) => {
          if (this.evaluationCard && this.evaluationCard.download_endpoint) {
            if (this.downloadLinkHtmlElement) {
              this.downloadLinkHtmlElement.setAttribute('href', URL.createObjectURL(new Blob([res.data], { type: this.evaluationCard.file_format })))
              this.downloadLinkHtmlElement.setAttribute('download', this.evaluationCard.file_name)
              this.downloadLinkHtmlElement.click()
        
              this.downloadLinkHtmlElement.removeAttribute('link')
              this.downloadLinkHtmlElement.removeAttribute('download')
            }
          }
        })
    }
  }

  private navigateToApplicationFormSupports(): void {
    this.mixin_router_navigate({
      name: ROUTES.EVALUATOR_APPLICATION_FORM_SUPPORT_DISPLAY,
      params: this.$route.params
    })
  }

  private async downloadAllFormAnswerFiles(): Promise<void> {
    this.isDownloadAllFormAnswerFilesLoadingStateActive = true

    await this.mixin_applicationForm_downloadAllFormAnswerFiles(this.mockForm || undefined)

    this.isDownloadAllFormAnswerFilesLoadingStateActive = false
  }
}
