import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { AxiosError } from 'axios'

import WithRender from './UserForm.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MForm,
  MMultiSelect,
  MSearchfield,
  MTextfield,
  RequiredValidator
} from '@ulaval/modul-components'

import { IUserModel } from '../User.model'
import UserService from '../User.service'
import RoleService from '../Role.service'
import { IRoleModel } from '../Role.model'

export interface IUserFormPayloadData {
  idul?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  roles: Array<string>;
}

@WithRender
@Component({
  components: {
    MForm,
    MMultiSelect,
    MSearchfield,
    MTextfield
  }
})
export default class UserForm extends mixins(RoleService, UserService) {
  public formGroup: FormGroup = new FormGroup({
    'role-multi-select': new FormControl<Array<string>>()
  })
  private formErrors: { [key: string]: string | boolean } = {}
  private roles: Array<IRoleModel> = []
  private isRoleLoadingStateActive: boolean = false
  private isLdapInformationsSearchLoadingStateActive: boolean = false
  private selectedRoles: string[] = []

  @Prop({ default: null })
  private user!: IUserModel | null

  @Prop({ default: null })
  private idulSearchBarPlaceholder!: string | null

  @Prop({ default: null })
  private idulSearchBarLabel!: string | null

  @Prop({ default: () => {
    return {}
  } })
  private requestErrors!: { [key: string]: string | boolean }

  @Prop({ default: true })
  private withRoleSelection!: boolean

  private get errors(): { [key: string]: string | boolean } {
    return {
      ...this.formErrors,
      ...this.requestErrors
    }
  }

  private get hideUserInformationsForm(): boolean {
    return !!this.user
  }

  public get idulSearchField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('idul-search');
    } catch (error) {
      return undefined
    }
  }

  public get usernameField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('username');
    } catch (error) {
      return undefined
    }
  }

  public get firstNameField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('first-name');
    } catch (error) {
      return undefined
    }
  }

  public get lastNameField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('last-name');
    } catch (error) {
      return undefined
    }
  }

  public get emailField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('email');
    } catch (error) {
      return undefined
    }
  }

  public get roleMultiSelect(): AbstractControl<Array<string>> {
    return this.formGroup.getControl('role-multi-select');
  }

  public get rolesSelectOptions(): Array<any> {
    return this.roles.map((role: IRoleModel) => role.label)
  }

  private get idulSearchFieldErrorMessage(): string | null {
    if (this.errors['idul']) {
      return this.$i18next.t('modules.user-management.user-form.UserForm.field.idul_search.errors.invalid_idul')
    } else if (this.errors['ldap_informations_not_found']) {
      return this.$i18next.t('modules.user-management.user-form.UserForm.field.idul_search.errors.ldap_informations_not_found')
    } else if (this.errors['user_already_exists']) {
      return this.$i18next.t('modules.user-management.user-form.UserForm.field.idul_search.errors.user_already_exists')
    } else if (
      (this.usernameField && this.usernameField.hasError()) ||
      (this.firstNameField && this.firstNameField.hasError()) ||
      (this.lastNameField && this.lastNameField.hasError()) ||
      (this.emailField && this.emailField.hasError())
    ) {
      return this.$i18next.t('modules.user-management.user-form.UserForm.field.idul_search.errors.form_empty')
    }

    return null
  }

  private created(): void {
    if (!this.hideUserInformationsForm) {
      this.formGroup.addControl('idul-search', new FormControl<string>([RequiredValidator()]))
      this.formGroup.addControl('username', new FormControl<string>([RequiredValidator()]))
      this.formGroup.addControl('first-name', new FormControl<string>([RequiredValidator()]))
      this.formGroup.addControl('last-name', new FormControl<string>([RequiredValidator()]))
      this.formGroup.addControl('email', new FormControl<string>([RequiredValidator()]))
    }

    this.fetchRoles()
  }

  private mounted(): void {
    if (this.user) {
      this.selectedRoles = this.user.roles.map((role: IRoleModel) => role.label)
    }
  }

  public fetchLdapUserInformations(): void {
    if (this.idulSearchField) {
      this.isLdapInformationsSearchLoadingStateActive = true

      this.service_userService_fetchUserLdapInformations(this.idulSearchField.value)
        .then((userInformations: IUserModel) => {
          if (userInformations) {
            if (this.usernameField) {
              this.usernameField.value = userInformations.username
            }
            if (this.emailField) {
              this.emailField.value = userInformations.email
            }
            if (this.firstNameField) {
              this.firstNameField.value = userInformations.first_name
            }
            if (this.lastNameField) {
              this.lastNameField.value = userInformations.last_name
            }
          }

          this.formErrors = {}
        }).catch((error: AxiosError<any>) => {
          this.clearUserInformations()

          if (
            error.response &&
            error.response.data &&
            error.response.data.validation
          ) {
            this.formErrors = error.response.data.validation
          } else {
            this.formErrors = {}
          }
        }).finally(() => {
          this.isLdapInformationsSearchLoadingStateActive = false
        })
    }
  }

  private fetchRoles(): void {
    if (this.withRoleSelection) {
      this.isRoleLoadingStateActive = true

      this.service_roleService_fetchList()
        .then((responseData: Array<IRoleModel>) => {
          this.roles = responseData || []
        }).catch(() => {
          this.roles = []
        }).finally(() => {
          this.isRoleLoadingStateActive = false
        })
    }
  }

  private clearUserInformations(): void {
    if (this.usernameField) {
      this.usernameField.value = ''
    }
    if (this.emailField) {
      this.emailField.value = ''
    }
    if (this.firstNameField) {
      this.firstNameField.value = ''
    }
    if (this.lastNameField) {
      this.lastNameField.value = ''
    }
  }

  public async getFormPayload(): Promise<IUserFormPayloadData | null> {
    await this.formGroup.submit()

    if (this.formGroup.hasErrorDeep()) {
      return null
    }

    return {
      idul: this.idulSearchField ? this.idulSearchField.value : undefined,
      first_name: this.firstNameField ? this.firstNameField.value : undefined,
      last_name: this.lastNameField ? this.lastNameField.value : undefined,
      email: this.emailField ? this.emailField.value : undefined,
      roles: this.mapSelectedRolesToSlugs()
    }
  }

  private mapSelectedRolesToSlugs(): Array<string> {
    const slugs: Array<string> = []

    this.roles.forEach((role: IRoleModel) => {
      if (this.selectedRoles.includes(role.label)) {
        slugs.push(role.slug)
      }
    })

    return slugs
  }

  public clear(): void {
    if (this.idulSearchField) {
      this.idulSearchField.value = ''
    }
    
    this.clearUserInformations()
  }
}
