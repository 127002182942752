import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import WithRender from './CategoryForm.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MCheckbox,
  MDatepicker,
  MForm,
  MIntegerfield,
  MTextarea,
  MTextfield,
  MTimepicker,
  RequiredValidator
} from '@ulaval/modul-components'

import { ICategoryModel } from '../Category.model'
import RichTextEditor from '@/modules/global/components/rich-text-editor/RichTextEditor'

export interface ICategoryFormPayloadData {
  title: string;
  description: string;
  application_deposit_limit_date: string;
  application_deposit_limit_time: string;
  evaluation_period_limit_date: string;
  evaluation_period_limit_time: string;
  sort_order: number;
  is_new_category: boolean;
  has_application_support: boolean;
  application_support_count_lower_bound?: number;
  application_support_count_upper_bound?: number;
  application_support_deposit_limit_date?: string;
  application_support_deposit_limit_time?: string;
  application_support_submit_limit_date?: string;
  application_support_submit_limit_time?: string;
  application_support_creation_dialog_message?: string;
  application_support_creation_email_message?: string;
}

const REF_APPLICATION_SUPPORT_CREATION_DIALOG_MESSAGE: string = 'application-support-creation-dialog-message'
const REF_APPLICATION_SUPPORT_CREATION_EMAIL_MESSAGE: string = 'application-support-creation-email-message'
const REF_DESCRIPTION_MESSAGE: string = 'description'

@WithRender
@Component({
  components: {
    MCheckbox,
    MDatepicker,
    MForm,
    MIntegerfield,
    MTextarea,
    MTextfield,
    MTimepicker,
    RichTextEditor
  }
})
export default class CategoryForm extends mixins() {
  public formGroup: FormGroup = new FormGroup({
    title: new FormControl<string>([RequiredValidator()]),
    application_deposit_limit_date: new FormControl<string>([RequiredValidator()]),
    application_deposit_limit_time: new FormControl<string>([RequiredValidator()]),
    evaluation_period_limit_date: new FormControl<string>([RequiredValidator()]),
    evaluation_period_limit_time: new FormControl<string>([RequiredValidator()]),
    sort_order: new FormControl<number>([RequiredValidator()]),
    is_new_category: new FormControl<boolean>(),
    has_application_support: new FormControl<boolean>()
  })
  private applicationSupportCreationDialogMessageModel: string = ''
  private applicationSupportCreationEmailMessageModel: string = ''
  private descriptionMessageModel: string = ''
  private formErrors: { [key: string]: string | boolean } = {}

  private readonly REF_APPLICATION_SUPPORT_CREATION_DIALOG_MESSAGE: string = REF_APPLICATION_SUPPORT_CREATION_DIALOG_MESSAGE
  private readonly REF_APPLICATION_SUPPORT_CREATION_EMAIL_MESSAGE: string = REF_APPLICATION_SUPPORT_CREATION_EMAIL_MESSAGE
  private readonly REF_DESCRIPTION_MESSAGE: string = REF_DESCRIPTION_MESSAGE

  @Prop({ default: null })
  private category!: ICategoryModel | null

  @Prop({ default: {} })
  private requestErrors!: { [key: string]: string | boolean }

  @Ref(REF_APPLICATION_SUPPORT_CREATION_DIALOG_MESSAGE)
  private applicationSupportCreationDialogMessageComponent!: RichTextEditor

  @Ref(REF_APPLICATION_SUPPORT_CREATION_EMAIL_MESSAGE)
  private applicationSupportCreationEmailMessageComponent!: RichTextEditor

  @Ref(REF_DESCRIPTION_MESSAGE)
  private descriptionMessageComponent!: RichTextEditor

  private get errors(): { [key: string]: string | boolean } {
    return {
      ...this.formErrors,
      ...this.requestErrors
    }
  }

  public get titleField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('title');
  }

  public get applicationDepositLimitDateField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('application_deposit_limit_date');
  }

  public get applicationDepositLimitTimeField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('application_deposit_limit_time');
  }

  public get evaluationPeriodLimitDateField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('evaluation_period_limit_date');
  }

  public get evaluationPeriodLimitTimeField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('evaluation_period_limit_time');
  }

  public get sortOrderField(): AbstractControl<number> {
    return this.formGroup.getControl<number>('sort_order');
  }

  public get isNewCategoryCheckbox(): AbstractControl<boolean> {
    return this.formGroup.getControl<boolean>('is_new_category');
  }

  public get hasApplicationSupportCheckbox(): AbstractControl<boolean> {
    return this.formGroup.getControl<boolean>('has_application_support');
  }

  public get applicationSupportCountLowerBoundField(): AbstractControl<number> | undefined {
    try {
      return this.formGroup.getControl<number>('application_support_count_lower_bound');
    } catch {
      return undefined
    }
  }

  public get applicationSupportCountUpperBoundField(): AbstractControl<number> | undefined {
    try {
      return this.formGroup.getControl<number>('application_support_count_upper_bound');
    } catch {
      return undefined
    }
  }

  public get applicationSupportDepositLimitDateField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('application_support_deposit_limit_date');
    } catch {
      return undefined
    }
  }

  public get applicationSupportDepositLimitTimeField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('application_support_deposit_limit_time');
    } catch {
      return undefined
    }
  }

  public get applicationSupportSubmitLimitDateField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('application_support_submit_limit_date');
    } catch {
      return undefined
    }
  }

  public get applicationSupportSubmitLimitTimeField(): AbstractControl<string> | undefined {
    try {
      return this.formGroup.getControl<string>('application_support_submit_limit_time');
    } catch {
      return undefined
    }
  }

  private get applicationSupportCountLowerBoundFieldErrorMessage(): string | null {
    if (this.errors['application_support_count_lower_bound_over_upper_bound_constraint']) {
      return this.$i18next.t(
        'modules.category-management.category-form.CategoryForm.field.application_support_count_lower_bound.errors.application_support_count_lower_bound_over_upper_bound_constraint'
      )
    }

    return null
  }

  @Watch('hasApplicationSupportCheckbox.value')
  private onHasApplicationSupportChange(value: boolean) {
    if (value) {
      if (!this.formGroup.containsControl('application_support_count_lower_bound')) {
        this.formGroup.addControl(
          'application_support_count_lower_bound',
          new FormControl<number>([RequiredValidator()])
        )
      }
      if (!this.formGroup.containsControl('application_support_count_upper_bound')) {
        this.formGroup.addControl(
          'application_support_count_upper_bound',
          new FormControl<number>([RequiredValidator()])
        )
      }
      if (!this.formGroup.containsControl('application_support_deposit_limit_date')) {
        this.formGroup.addControl(
          'application_support_deposit_limit_date',
          new FormControl<string>([RequiredValidator()])
        )
      }
      if (!this.formGroup.containsControl('application_support_deposit_limit_time')) {
        this.formGroup.addControl(
          'application_support_deposit_limit_time',
          new FormControl<string>([RequiredValidator()])
        )
      }
      if (!this.formGroup.containsControl('application_support_submit_limit_date')) {
        this.formGroup.addControl(
          'application_support_submit_limit_date',
          new FormControl<string>([RequiredValidator()])
        )
      }
      if (!this.formGroup.containsControl('application_support_submit_limit_time')) {
        this.formGroup.addControl(
          'application_support_submit_limit_time',
          new FormControl<string>([RequiredValidator()])
        )
      }
    
    } else {
      this.formGroup.removeControl('application_support_deposit_limit_date')
      this.formGroup.removeControl('application_support_deposit_limit_time')
      this.formGroup.removeControl('application_support_count_lower_bound')
      this.formGroup.removeControl('application_support_count_upper_bound')
      this.formGroup.removeControl('application_support_submit_limit_date')
      this.formGroup.removeControl('application_support_submit_limit_time')
      this.applicationSupportCreationDialogMessageModel = ''
      this.applicationSupportCreationEmailMessageModel = ''
    }
  }

  private mounted(): void {
    this.populateFormFromModel()
  }

  private populateFormFromModel(): void {
    if (this.category) {
      this.titleField.value = this.category.title
      this.descriptionMessageModel = this.category.description
      this.applicationDepositLimitDateField.value = this.mixin_timestamp_extractDate(this.category.application_deposit_limit_timestamp)
      this.applicationDepositLimitTimeField.value = this.mixin_timestamp_extractTime(this.category.application_deposit_limit_timestamp)
      this.evaluationPeriodLimitDateField.value = this.mixin_timestamp_extractDate(this.category.evaluation_period_limit_timestamp)
      this.evaluationPeriodLimitTimeField.value = this.mixin_timestamp_extractTime(this.category.evaluation_period_limit_timestamp)
      this.sortOrderField.value = this.category.sort_order
      this.isNewCategoryCheckbox.value = this.category.is_new_category || false
      this.hasApplicationSupportCheckbox.value = this.category.has_application_support || false
      this.onHasApplicationSupportChange(this.category.has_application_support)

      if (this.category.has_application_support) {
        if (this.applicationSupportCountLowerBoundField && this.category.application_support_count_lower_bound) {
          this.applicationSupportCountLowerBoundField.value = this.category.application_support_count_lower_bound
        }
        if (this.applicationSupportCountUpperBoundField && this.category.application_support_count_upper_bound) {
          this.applicationSupportCountUpperBoundField.value = this.category.application_support_count_upper_bound
        }
        if (this.applicationSupportDepositLimitDateField) {
          this.applicationSupportDepositLimitDateField.value = this.mixin_timestamp_extractDate(this.category.application_support_deposit_limit_timestamp || '')
        }
        if (this.applicationSupportDepositLimitTimeField) {
          this.applicationSupportDepositLimitTimeField.value = this.mixin_timestamp_extractTime(this.category.application_support_deposit_limit_timestamp || '')
        }
        if (this.applicationSupportSubmitLimitDateField) {
          this.applicationSupportSubmitLimitDateField.value = this.mixin_timestamp_extractDate(this.category.application_support_submit_limit_timestamp || '')
        }
        if (this.applicationSupportSubmitLimitTimeField) {
          this.applicationSupportSubmitLimitTimeField.value = this.mixin_timestamp_extractTime(this.category.application_support_submit_limit_timestamp || '')
        }

        this.applicationSupportCreationDialogMessageModel = this.category.application_support_creation_dialog_message || ''
        this.applicationSupportCreationEmailMessageModel = this.category.application_support_creation_email_message || ''
      }
    }
  }

  public async getFormPayload(): Promise<ICategoryFormPayloadData | null> {
    let areRichTextEditorsValid: boolean = true
    await this.formGroup.submit()
    
    if (this.applicationSupportCreationDialogMessageComponent && (!await this.applicationSupportCreationDialogMessageComponent.validate())) {
      areRichTextEditorsValid = false
    }
    if (this.applicationSupportCreationEmailMessageComponent && (!await this.applicationSupportCreationEmailMessageComponent.validate())) {
      areRichTextEditorsValid = false
    }
    if (this.descriptionMessageComponent && (!await this.descriptionMessageComponent.validate())) {
      areRichTextEditorsValid = false
    }

    if (this.formGroup.hasErrorDeep() || !areRichTextEditorsValid) {
      return null
    }

    const formPayloadData: ICategoryFormPayloadData = {
      title: this.titleField.value,
      description: this.descriptionMessageModel,
      application_deposit_limit_date: this.applicationDepositLimitDateField.value,
      application_deposit_limit_time: this.applicationDepositLimitTimeField.value,
      evaluation_period_limit_date: this.evaluationPeriodLimitDateField.value,
      evaluation_period_limit_time: this.evaluationPeriodLimitTimeField.value,
      sort_order: this.sortOrderField.value,
      is_new_category: this.isNewCategoryCheckbox.value,
      has_application_support: this.hasApplicationSupportCheckbox.value
    }

    if (this.hasApplicationSupportCheckbox.value) {
      if (this.applicationSupportCountLowerBoundField) {
        formPayloadData.application_support_count_lower_bound = this.applicationSupportCountLowerBoundField.value
      }
      if (this.applicationSupportCountUpperBoundField) {
        formPayloadData.application_support_count_upper_bound = this.applicationSupportCountUpperBoundField.value
      }
      if (this.applicationSupportDepositLimitDateField) {
        formPayloadData.application_support_deposit_limit_date = this.applicationSupportDepositLimitDateField.value
      }
      if (this.applicationSupportDepositLimitTimeField) {
        formPayloadData.application_support_deposit_limit_time = this.applicationSupportDepositLimitTimeField.value
      }
      if (this.applicationSupportSubmitLimitDateField) {
        formPayloadData.application_support_submit_limit_date = this.applicationSupportSubmitLimitDateField.value
      }
      if (this.applicationSupportSubmitLimitTimeField) {
        formPayloadData.application_support_submit_limit_time = this.applicationSupportSubmitLimitTimeField.value
      }
      formPayloadData.application_support_creation_dialog_message = this.applicationSupportCreationDialogMessageModel
      formPayloadData.application_support_creation_email_message = this.applicationSupportCreationEmailMessageModel
    }

    return formPayloadData
  }
}
