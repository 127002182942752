import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WithRender from './ApplicationFormCard.html'

import {
  MAdd,
  MDialog,
  MDialogState,
  MDialogWidth,
  MIcon,
  MLink,
  MLinkMode,
  MOption,
  MOptionItemDelete,
  MPanel,
  MToast,
  MToastDuration,
  MToastPosition,
  MToastState,
  MToastTimeout,
} from '@ulaval/modul-components'

import SoftDeleteItemOption from '@/modules/global/components/option-item/SoftDeleteItemOption'
import RestoreItemOption from '@/modules/global/components/option-item/RestoreItemOption'
import SubmitApplicationFormSupportOverlay from './SubmitApplicationFormSupportOverlay'

import ApplicationFormMixin from '@/modules/application-form-management/ApplicationForm.mixin'
import { IApplicationFormModel } from '@/modules/application-form-management/ApplicationForm.model'

import ROUTES from '@/router/ROUTES'
import ApplicationFormService from '../ApplicationForm.service'


@WithRender
@Component({
  components: {
    MAdd,
    MDialog,
    MIcon,
    MLink,
    MOption,
    MOptionItemDelete,
    MPanel,
    MToast,
    SoftDeleteItemOption,
    SubmitApplicationFormSupportOverlay,
    RestoreItemOption
  }
})
export default class ApplicationFormCard extends mixins(ApplicationFormMixin, ApplicationFormService) {
  private isDeleteDialogVisible: boolean = false
  private isDeleteLoadingStateActive: boolean = false
  private isSoftDeleteSuccessToastOpen: boolean = false
  private isRestoreSuccessToastOpen: boolean = false
  private isSubmitApplicationFormSupportOverlayDisplayed: boolean = false

  private readonly MDialogState: object = MDialogState
  private readonly MDialogWidth: object = MDialogWidth
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly MToastTimeout: object = MToastTimeout
  private readonly MLinkMode: object = MLinkMode

  @Prop({ required: true })
  private applicationForm!: IApplicationFormModel

  private get panelStyles(): object {
    return {
      'border-bottom-color': this.mixin_applicationForm_computeStatusColor(this.applicationForm)
    }
  }

  private get panelClasses(): object {
    return {
      'application-form-card--disabled': this.mixin_applicationForm_isApplicationFormInactive(this.applicationForm)
    }
  }

  private get statusIconStyles(): object {
    return {
      'color': this.mixin_applicationForm_computeStatusColor(this.applicationForm)
    }
  }

  private navigateToApplicationForm(): void {
    this.mixin_router_navigate({
      name: ROUTES.APPLICATION_FORM,
      params: {
        idApplicationForm: this.applicationForm.id
      }
    })
  }

  private deleteApplicationForm(): void {
    this.isDeleteLoadingStateActive = true

    this.service_applicationFormService_deleteApplicationForm(this.applicationForm.id)
      .finally(() => {
        this.isDeleteLoadingStateActive = false
      })
  }

  private softDeleteApplicationForm(): void {
    this.isSoftDeleteSuccessToastOpen = true

    this.service_applicationFormService_softDeleteApplicationForm(this.applicationForm.id)
      .then(() => {
        this.isSoftDeleteSuccessToastOpen = true
      }).finally(() => {
        setTimeout(() => {
          this.isSoftDeleteSuccessToastOpen = false
        }, MToastDuration.DesktopXShort)
      })
  }

  private restoreApplicationForm(): void {
    this.isRestoreSuccessToastOpen = true
    
    this.service_applicationFormService_restoreApplicationForm(this.applicationForm.id)
      .then(() => {
        this.isRestoreSuccessToastOpen = true
      }).finally(() => {
        setTimeout(() => {
          this.isRestoreSuccessToastOpen = false
        }, MToastDuration.DesktopXShort)
      })
  }

  private displayDeleteDialog(): void {
    this.isDeleteDialogVisible = true
  }

  private hideDeleteDialog(): void {
    this.isDeleteDialogVisible = false
    this.isDeleteLoadingStateActive = false
  }

  private displaySubmitApplicationFormSupportDialog(): void {
    this.isSubmitApplicationFormSupportOverlayDisplayed = true
  }

  private hideSubmitApplicationFormSupportDialog(): void {
    this.isSubmitApplicationFormSupportOverlayDisplayed = false
  }
}
