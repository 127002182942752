<section class="admin-application-form main-content">
  <breadcrumbs />
  <m-panel class="main-content__panel">
    <template v-if="applicationForm">
      <h3 slot="header">
        {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.header.title') }}
      </h3>
    </template>
    <template v-if="!isLoadingStateActive && applicationForm">
      <div class="admin-application-form--informations mb-6">
        <m-panel>
          <ul class="mb-0">
            <li>
              <strong>
                {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.informations.category') }}
              </strong>
              {{ applicationForm.category_title }}
            </li>
            <li class="mt-2">
              <strong>
                {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.informations.candidate') }}
              </strong>
              {{ applicationForm.candidate_full_name }}
            </li>
            <template v-if="applicationForm.deposit_deadline_extension_timestamp">
              <li class="mt-2">
                <strong>
                  {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.informations.deposit_deadline_extension_timestamp') }}
                </strong>
                {{ mixin_timestamp_pretty(applicationForm.deposit_deadline_extension_timestamp) }}
              </li>
            </template>
            <li class="mt-2">
              <strong>
                {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.informations.category_application_deposit_limit_timestamp') }}
              </strong>
              {{ mixin_timestamp_pretty(applicationForm.category_application_deposit_limit_timestamp) }}
            </li>
            <template v-if="applicationForm.category_application_support_submit_limit_timestamp">
              <li class="mt-2">
                <strong>
                  {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.informations.category_application_support_submit_limit_timestamp') }}
                </strong>
                {{ mixin_timestamp_pretty(applicationForm.category_application_support_submit_limit_timestamp) }}
              </li>
            </template>
            <li class="mt-2">
              <div class="display--flex">
                <strong class="mx-0 my-auto">
                  {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.informations.status') }}
                </strong>
                <template v-if="mixin_applicationForm_computeStatusIcon(applicationForm)">
                  <m-icon
                    class="mx-2 my-auto"
                    size="22px"
                    :style="mixin_applicationForm_computeStatusIconStyles(applicationForm)"
                    :name="mixin_applicationForm_computeStatusIcon(applicationForm)"
                  />
                </template>
                <div class="mx-0 mt-auto">
                  {{ mixin_applicationForm_computeStatusLabel(applicationForm.status) }}
                </div>
              </div>
            </li>
          </ul>
        </m-panel>
      </div>
    </template>
    <template
      #header-right-content
      v-if="(form && mixin_applicationForm_hasFormAnswerFiles(form)) && !isLoadingStateActive"
    >
      <template v-if="form && mixin_applicationForm_hasFormAnswerFiles(form)">
        <m-button
          :skin="MButtonSkin.Secondary"
          :waiting="isDownloadAllFormAnswerFilesLoadingStateActive"
          @click="downloadAllFormAnswerFiles"
        >
          {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.button.download_form_answer_files.label') }}
        </m-button>
      </template>
    </template>
    <template v-if="isLoadingStateActive">
      <div
        key="spinner"
        class="my-15"
      >
        <m-spinner
          :title="true"
          :description="true"
        />
      </div>
    </template>
    <div v-show="!isLoadingStateActive">
      <template v-if="internalFormTemplate && form && applicationForm">
        <form-elements
          :ref="FORM_ELEMENTS_REF"
          :key="internalFormTemplate.internal_identifier"
          :elements="internalFormTemplate.elements"
          :answers="form.form_answers"
          :display-evaluator-additionnal-informations="true"
        />
      </template>
    </div>
    <template
      #footer
      v-show="applicationForm && !isLoadingStateActive"
    >
      <m-dialog
        :state="submitDialogState"
        :width="MDialogWidth.Large"
        :open="isSubmitConfirmationDialogActive"
        :message="$i18next.t('modules.application-form-management.application-form.AdminApplicationForm.dialog.submit_confirmation.label')"
        :okLabel="$i18next.t('modules.application-form-management.application-form.AdminApplicationForm.dialog.submit_confirmation.submit_button.label')"
        @ok="onSubmitButtonClick"
        @portal-after-close="hideSubmitConfirmationDialog"
      >
        <template
          #trigger
          v-if="!isLoadingStateActive"
        >
          <m-button @click="displaySubmitConfirmationDialog">
            {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.button.submit.label') }}
          </m-button>
        </template>
      </m-dialog>
    </template>
  </m-panel>
  <m-toast
    :state="MToastState.Success"
    :position="MToastPosition.TopRight"
    :open="isSubmitSuccessToastOpen"
  >
    {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.toast.submit_success.label') }}
  </m-toast>
  <m-toast
    :state="MToastState.Error"
    :position="MToastPosition.TopRight"
    :open="isSubmitErrorToastOpen"
  >
    {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.toast.submit_error.label') }}
  </m-toast>
  <m-toast
    :state="MToastState.Warning"
    :position="MToastPosition.TopCenter"
    :open="isValidationErrorToastOpen"
  >
    <p class="m-0">
      <strong>
        {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.toast.validation_error.label') }}
      </strong>
      <br />
      {{ $i18next.t('modules.application-form-management.application-form.AdminApplicationForm.toast.validation_error.help') }}
    </p>
  </m-toast>
</section>