import Component, { mixins } from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'

import WithRender from './AdminApplicationFormValidation.html'

import { 
  MButton,
  MButtonSkin,
  MIcon,
  MMessage,
  MMessageState,
  MPanel,
  MSpinner,
  MToast,
  MToastPosition,
  MToastState,
  MToastTimeout,
} from '@ulaval/modul-components'

import Breadcrumbs from '@/modules/global/breadcrumbs/Breadcrumbs'
import FormElements from '@/modules/form-management/form-element/FormElements'

import ApplicationFormValidationService, { IFetchApplicationFormValidationResponse } from '../ApplicationFormValidation.service'
import { ApplicationFormValidationStatus, IApplicationFormValidationModel } from '../ApplicationFormValidation.model'
import { IFormModel } from '@/modules/form-management/Form.model'
import { IInternalFormTemplateModel } from '@/modules/form-template-management/FormTemplate.model'
import { IApplicationFormModel } from '@/modules/application-form-management/ApplicationForm.model'
import { IFormAnswerForm } from '@/modules/form-management/Form.service'
import { AxiosError } from 'axios'
import ApplicationFormMixin from '@/modules/application-form-management/ApplicationForm.mixin'

const APPLICATION_FORM_VALIDATION_FORM_ELEMENTS: string = "application-form-validation-form-elements"

@WithRender
@Component({
  components: {
    Breadcrumbs,
    FormElements,
    MButton,
    MIcon,
    MMessage,
    MPanel,
    MSpinner,
    MToast
  }
})
export default class AdminApplicationFormValidation extends mixins(ApplicationFormValidationService, ApplicationFormMixin) {
  private applicationFormValidation: IApplicationFormValidationModel | null = null
  private applicationFormValidationForm: IFormModel | null = null
  private applicationFormValidationInternalFormTemplate: IInternalFormTemplateModel | null = null
  private applicationForm: IApplicationFormModel | null = null
  private applicationFormForm: IFormModel | null = null
  private applicationFormInternalFormTemplate: IInternalFormTemplateModel | null = null
  private isLoadingStateActive: boolean = true
  private isUpdateApplicationFormValidationSuccessToastOpen: boolean = false
  private isDownloadAllFormAnswerFilesLoadingStateActive: boolean = false

  private readonly MMessageState: object = MMessageState
  private readonly MButtonSkin: object = MButtonSkin
  private readonly MToastPosition: object = MToastPosition
  private readonly MToastState: object = MToastState
  private readonly MToastTimeout: object = MToastTimeout
  private readonly APPLICATION_FORM_VALIDATION_FORM_ELEMENTS: string = APPLICATION_FORM_VALIDATION_FORM_ELEMENTS

  @Ref(APPLICATION_FORM_VALIDATION_FORM_ELEMENTS)
  private applicationFormValidationFormElementsComponent!: FormElements

  @Watch('$route.params.idApplicationFormValidation', { immediate: true })
  private onRouteParamsIdChange(value: string | null) {
    if (value) {
      this.loadApplicationFormValidation(value)
    }
  }

  private loadApplicationFormValidation(id: string): void {
    this.isLoadingStateActive = true

    this.service_applicationFormValidationService_fetchApplicationForm(id)
      .then((response: IFetchApplicationFormValidationResponse) => {
        this.applicationFormValidation = response.application_form_validation.application_form_validation || null
        this.applicationFormValidationForm = response.application_form_validation.form || null
        this.applicationFormValidationInternalFormTemplate = response.application_form_validation.internal_form_template || null
        this.applicationForm = response.application_form.application_form || null
        this.applicationFormForm = response.application_form.form || null
        this.applicationFormInternalFormTemplate = response.application_form.internal_form_template || null
      }).catch(() => {
        this.applicationFormValidation = null
        this.applicationFormValidationForm = null
        this.applicationFormValidationInternalFormTemplate = null
        this.applicationForm = null
        this.applicationFormForm = null
        this.applicationFormInternalFormTemplate = null
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }

  private async onSaveApplicationFormValidationButtonClick(): Promise<void> {
    const answers: Array<IFormAnswerForm> | null = await this.applicationFormValidationFormElementsComponent.getElementAnswers(false)

    if (answers) {
      this.saveApplicationFormValidation(answers)
    }
  }

  private saveApplicationFormValidation(answers: Array<IFormAnswerForm>): void {
    if (this.applicationFormValidation) {
      this.isLoadingStateActive = true
      this.isUpdateApplicationFormValidationSuccessToastOpen = false

      this.service_applicationFormValidationService_save(this.applicationFormValidation.id, answers)
        .then(() => {
          this.isUpdateApplicationFormValidationSuccessToastOpen = true
          this.loadApplicationFormValidation(this.$route.params.idApplicationFormValidation)
        }).catch((error: AxiosError) => {
          this.isLoadingStateActive = false
          this.mixin_router_redirectRequestError(error)
        })
    }
  }

  private onFlagApplicationFormValidationAsValidButtonClick(): void {
    this.updateApplicationFormValidationStatus(ApplicationFormValidationStatus.VALID)
  }

  private onFlagApplicationFormValidationAsInvalidButtonClick(): void {
    this.updateApplicationFormValidationStatus(ApplicationFormValidationStatus.INVALID)
  }

  private updateApplicationFormValidationStatus(status: ApplicationFormValidationStatus): void {
    if (this.applicationFormValidation) {
      this.isLoadingStateActive = true
      this.isUpdateApplicationFormValidationSuccessToastOpen = false

      this.service_applicationFormValidationService_updateStatus(this.applicationFormValidation.id, { status })
        .then(() => {
          this.isUpdateApplicationFormValidationSuccessToastOpen = true
          this.loadApplicationFormValidation(this.$route.params.idApplicationFormValidation)
        }).catch((error: AxiosError) => {
          this.isLoadingStateActive = false
          this.mixin_router_redirectRequestError(error)
        })
    }
  }

  private async downloadAllFormAnswerFiles(): Promise<void> {
    this.isDownloadAllFormAnswerFilesLoadingStateActive = true

    await this.mixin_applicationForm_downloadAllFormAnswerFiles(this.applicationFormForm || undefined)

    this.isDownloadAllFormAnswerFilesLoadingStateActive = false
  }
}
