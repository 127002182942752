<m-panel class="public-category-card">
  <template #header>
    <m-link @click.native="onCategoryTitleClick">
      {{ category.title }}
    </m-link>
  </template>
  <template #header-right-content>
    <template v-if="category.is_new_category">
      {{ $i18next.t('modules.category-management.category.PublicCategoryCard.label.new_category') }}
    </template>
  </template>
  <p v-html="category.description" />
  <ul class="mb-0">
    <li>
      <strong>
        {{ $i18next.t('modules.category-management.category.PublicCategoryCard.label.application_form_deposit_limit_timestamp') }}
      </strong>
      {{ mixin_timestamp_pretty(category.application_deposit_limit_timestamp) }}
    </li>
    <template v-if="category.has_application_support">
      <li>
        <strong>
          {{ $i18next.t('modules.category-management.category.PublicCategoryCard.label.application_form_support_count.label') }}
        </strong>
        <template v-if="category.application_support_count_lower_bound === category.application_support_count_upper_bound">
          {{ category.application_support_count_lower_bound }}
        </template>
        <template v-else>
          <template v-if="category.application_support_count_lower_bound <= 1">
            {{ $i18next.t('modules.category-management.category.PublicCategoryCard.label.application_form_support_count.value.threshold_different.singular', category) }}
          </template>
          <template v-else>
            {{ $i18next.t('modules.category-management.category.PublicCategoryCard.label.application_form_support_count.value.threshold_different.plural', category) }}
          </template>
        </template>
      </li>
    </template>
    <template v-if="isApplicationFormDepositTimestampOverThreshold">
      <li class="public-category-card--application-form-deposit-timestamp-over-threshold display--flex">
        <m-icon
          class="pr-1"
          name="m-svg__error"
          size="18px"
        />
        <small>
          {{ $i18next.t('modules.category-management.category.PublicCategoryCard.label.application_form_deposit_timestamp_over_threshold') }}
        </small>
      </li>
    </template>
  </ul>
</m-panel>