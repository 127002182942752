<m-form
  class="application-form-management__application-form-form"
  :form-group="formGroup"
>
  <div class="application-form-management__application-form-form--row">
    <div class="application-form-management__application-form-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.category_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <div class="application-form-management__application-form-form--row">
    <div class="application-form-management__application-form-form--column">
      <m-dropdown
        v-model="categoryDropdown.value"
        v-m-control="categoryDropdown"
        :label="$i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.dropdown.category.label')"
        :required-marker="true"
        :error="categoryDropdown.hasError()"
        :error-message="categoryDropdown.errorMessage"
        max-width="none"
        :waiting="isCategoryLoadingStateActive"
        :filterable="true"
      >
        <template v-for="category in categories">
          <m-dropdown-item
            :value="category.id"
            :label="category.title"
          >
            {{ category.title }}
          </m-dropdown-item>
        </template>
      </m-dropdown>
    </div>
  </div>
  <div class="application-form-management__application-form-form--row mt-12">
    <div class="application-form-management__application-form-form--column">
      <label class="m-u--font-weight--bold">
        {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.candidate_zone') }}
      </label>
      <hr />
    </div>
  </div>
  <user-form
    :ref="USER_FORM_REF"
    class="mt-4"
    :with-role-selection="false"
    :idul-search-bar-placeholder="$i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.user_form_idul_placeholder')"
    :idul-search-bar-label="$i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.user_form_idul_label')"
  />
  <template v-if="categoriesMappedById[categoryDropdown.value] && categoriesMappedById[categoryDropdown.value].has_application_support">
    <div class="application-form-management__application-form-form--row mt-12">
      <div class="application-form-management__application-form-form--column">
        <label class="m-u--font-weight--bold">
          {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.application_form_support_zone') }}
        </label>
        <hr />
      </div>
      <div class="application-form-management__application-form-form--column">
        <template v-if="selectedCategory && !selectedCategory.is_application_form_support_submittable">
          <m-message :state="MMessageState.Warning">
            <span v-html="$i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.message.application_form_support_not_available.content')" />
          </m-message>
        </template>
        <template v-else>
          <m-message :state="MMessageState.Information">
            <span v-html="$i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.message.application_form_support_optional.content')" />
          </m-message>
        </template>
      </div>
      <template v-if="selectedCategory && !mixin_string_isEmpty(selectedCategory.application_support_creation_dialog_message)">
        <div class="application-form-management__application-form-form--column">
          <span v-html="selectedCategory.application_support_creation_dialog_message" />
        </div>
      </template>
    </div>
    <template v-if="selectedCategory && selectedCategory.is_application_form_support_submittable">
      <template v-if="applicationFormSupportForms.length === 0">
        <m-add
          class="mt-4"
          @click="increaseApplicationFormSupportFormCount(0)"
        >
          {{ $i18next.t('modules.application-form-management.application-form-form.ApplicationFormForm.label.add_application_form_support') }}
        </m-add>
      </template>
      <template v-for="key, index in applicationFormSupportForms">
        <application-form-support-form
          :key="key"
          class="mt-2"
          :ref="APPLICATION_FORM_SUPPORT_FORM_REF"
          :is-add-icon-displayed="(index === applicationFormSupportForms.length - 1) && (index < categoriesMappedById[categoryDropdown.value].application_support_count_upper_bound)"
          :is-delete-icon-displayed="true"
          :is-add-icon-column-displayed="applicationFormSupportForms.length < categoriesMappedById[categoryDropdown.value].application_support_count_upper_bound"
          :is-delete-icon-column-displayed="true"
          @add-icon:click="increaseApplicationFormSupportFormCount(key)"
          @delete-icon:click="decreaseApplicationFormSupportFormCount(index)"
        />
      </template>
    </template>
  </template>
</m-form>