import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import { IAdminApplicationFormModel, IApplicationFormModel } from "./ApplicationForm.model";
import { IFormAnswerFileModel, IFormModel } from "../form-management/Form.model";
import FormTemplateService from "../form-template-management/FormTemplate.service";
import { IInternalFormTemplateModel } from "../form-template-management/FormTemplate.model";
import { ICategoryModel } from "../category-management/Category.model";
import { EVENT_BUS_EVENTS } from "../global/utilities/Events";
import { IFormAnswerForm } from "../form-management/Form.service";
import { IFileUploadModel } from "../global/file/File.model";
import { IPaginationQueryParameters } from "../global/utilities/Base.service";
import { IPaginatedResponse } from "../global/utilities/Pagination.model";

export interface IFetchApplicationFormResponse {
  application_form: IApplicationFormModel;
  form: IFormModel;
  internal_form_template: IInternalFormTemplateModel;
}

export interface ICreateApplicationFormForm {
  category_id: string;
  candidate_idul: string;
  application_form_supports?: Array<{
    supporter_name: string;
    supporter_email: string;
  }>;
}

export interface IFetchAdminApplicationFormsListQueryParameters extends IPaginationQueryParameters {
  year_id: string;
  query?: string;
  status: Array<string>;
}

export interface IFetchAdminApplicationFormsListResponse extends IPaginatedResponse {
  application_forms: Array<IAdminApplicationFormModel>;
}

export interface ISendApplicationFormReminderEmailForm {
  categories_id: Array<string>;
}

export interface ISendApplicationFormSupportSubmitReminderEmailForm {
  categories_id: Array<string>;
}

export interface IApplicationFormDepositDeadlineExtensionForm {
  timestamp: string;
}

export interface IApplicationFormSubmitApplicationFormSupportForm {
  application_form_id: string;
  application_form_supports: Array<{
    supporter_name: string;
    supporter_email: string;
  }>;
}

export interface IApplicationFormAuthorizedUsersForm {
  iduls: string[];
}

export interface IAdminDownloadExportFileQueryParameters {
  year_id: string;
  status: Array<string>;
}

@Component
export default class ApplicationFormService extends FormTemplateService {
  protected service_applicationFormService_fetchUserApplicationFormList(yearId: string): Promise<Array<IApplicationFormModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery({
        year_id: yearId
      })

      this.$axios.get(`/api/application-forms?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.application_forms))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_fetchApplicationForm(id: string): Promise<IFetchApplicationFormResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/application-forms/${id}`)
        .then((response: AxiosResponse) => resolve({
          application_form: response.data.data.application_form,
          form: response.data.data.form,
          internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.form.form_template)
        })).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_fetchAvailableCategories(yearId: string): Promise<Array<ICategoryModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery({
        year_id: yearId
      })

      this.$axios.get(`/api/application-forms/available-categories?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.categories))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_createApplicationForm(payload: ICreateApplicationFormForm): Promise<IApplicationFormModel> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post(`/api/application-forms`, payload)
        .then((response: AxiosResponse) => {
          const createdApplicationForm: IApplicationFormModel = response.data.data.application_form

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_CREATED, createdApplicationForm)

          resolve(createdApplicationForm)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_deleteApplicationForm(id: string): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.delete(`/api/application-forms/${id}`)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_DELETED, id)

          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_softDeleteApplicationForm(id: string): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/application-forms/${id}/soft-delete`)
        .then((response: AxiosResponse) => {
          const updatedApplicationForm: IApplicationFormModel = response.data.data.application_form

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_UPDATED, updatedApplicationForm)

          resolve(updatedApplicationForm)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_restoreApplicationForm(id: string): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/application-forms/${id}/restore`)
        .then((response: AxiosResponse) => {
          const updatedApplicationForm: IApplicationFormModel = response.data.data.application_form

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_UPDATED, updatedApplicationForm)

          resolve(updatedApplicationForm)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_saveApplicationForm(id: string, answers: Array<IFormAnswerForm>): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(
        `/api/application-forms/${id}`, 
        this.service_applicationFormService_generateAnswersFormData(answers), 
        {
          headers: this.service_baseService_multipartFormDataHeader()
        }
      ).then(() => {
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_submitApplicationForm(id: string, answers: Array<IFormAnswerForm>): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(
        `/api/application-forms/${id}/submit`, 
        this.service_applicationFormService_generateAnswersFormData(answers), 
        {
          headers: this.service_baseService_multipartFormDataHeader()
        }
      ).then(() => {
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  private service_applicationFormService_generateAnswersFormData(answers: Array<IFormAnswerForm>): FormData {
    const data: FormData = new FormData()

    answers.forEach((answerForm: any, index: number) => {
      for (const key in answerForm) {
        const answerValue: any = answerForm[key]

        if (
          answerValue != undefined && 
          answerValue != null &&
          key != 'files'
        ) {
          data.append(`answers[${index}].${key}`, answerValue)
        }
      }

      if (answerForm.files) {
        answerForm.files.forEach((uploadedFile: IFileUploadModel<IFormAnswerFileModel>, fileIndex: number) => {
          if (uploadedFile.server_file) {
            data.set(`answers[${index}].form_answer_files[${fileIndex}].id`, uploadedFile.server_file.id)
          } else if (uploadedFile && uploadedFile.file) {
            data.append(`answers[${index}].form_answer_files[${fileIndex}].file`, uploadedFile.file.file)
          }
        })
      }
    })

    return data
  }

  protected service_applicationFormService_fetchAdminApplicationFormList(query: IFetchAdminApplicationFormsListQueryParameters): Promise<IFetchAdminApplicationFormsListResponse> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/admin/application-forms?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_sendApplicationFormReminderEmails(payload: ISendApplicationFormReminderEmailForm): Promise<IFetchAdminApplicationFormsListResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post(`/api/admin/application-forms/reminder-emails`, payload)
        .then((response: AxiosResponse) => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_REMINDER_EMAIL_SENT, null)

          return resolve(response.data.data)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_fetchAdminApplicationForm(id: string): Promise<IFetchApplicationFormResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/admin/application-forms/${id}`)
        .then((response: AxiosResponse) => resolve({
          application_form: response.data.data.application_form,
          form: response.data.data.form,
          internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.form.form_template)
        })).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_updateDepositDeadlineExtension(id: string, form: IApplicationFormDepositDeadlineExtensionForm): Promise<IFetchApplicationFormResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/admin/application-forms/${id}/extend-deposit-deadline`, form)
        .then((response: AxiosResponse) => {
          const updatedApplicationForm: IApplicationFormModel = response.data.data.application_form

          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_UPDATED, updatedApplicationForm)

          resolve(updatedApplicationForm)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_submitApplicationFormSupport(payload: IApplicationFormSubmitApplicationFormSupportForm): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/application-forms/application-form-supports`, payload)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_APPLICATION_FORM_SUPPORT_SUBMITTED, null)

          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_sendApplicationFormSupportSubmitReminderEmails(payload: ISendApplicationFormSupportSubmitReminderEmailForm): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post(`/api/admin/application-forms/support-reminder-emails`, payload)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_SUPPORT_SUBMIT_REMINDER_EMAIL_SENT, null)

          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_deleteApplicationFormAdmin(id: string): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.delete(`/api/admin/application-forms/${id}`)
        .then(() => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_DELETED, id)

          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_saveApplicationFormAdmin(id: string, answers: Array<IFormAnswerForm>): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(
        `/api/admin/application-forms/${id}`, 
        this.service_applicationFormService_generateAnswersFormData(answers), 
        {
          headers: this.service_baseService_multipartFormDataHeader()
        }
      ).then(() => {
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_saveAuthorizedUsersAdmin(id: string, form: IApplicationFormAuthorizedUsersForm): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(`/api/admin/application-forms/${id}/authorized-users`, form).then(() => {
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_deleteApplicationFormSupport(idApplicationForm: string, idApplicationFormSupport: string): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.delete(`/api/application-forms/${idApplicationForm}/application-form-supports/${idApplicationFormSupport}`)
        .then(() => {
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormService_adminDownloadExportFile(query: IAdminDownloadExportFileQueryParameters): Promise<any> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/admin/application-forms/export?${queryString}`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream'
        }
      }).then((response: any) => {
          resolve(response)
        }).catch((error: AxiosError) => reject(error))
    })
  }
}