<m-form
  :form-group="formGroup"
  class="category-management__category-form mb-8"
>
  <div class="category-management__category-form--row">
    <div class="category-management__category-form--column">
      <m-textfield
        v-model="titleField.value"
        v-m-control="titleField"
        :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.title.label')"
        max-width="none"
        :error="titleField.hasError()"
        :error-message="titleField.errorMessage"
        :requiredMarker="true"
      />
    </div>
  </div>
  <div class="category-management__category-form--row">
    <div class="category-management__category-form--column">
      <rich-text-editor
        v-model="descriptionMessageModel"
        :ref="REF_DESCRIPTION_MESSAGE"
        :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.description.label')"
      />
    </div>
  </div>
  <div class="category-management__category-form--row">
    <div class="category-management__category-form--column">
      <m-integerfield
        v-model="sortOrderField.value"
        v-m-control="sortOrderField"
        :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.sort_order.label')"
        max-width="none"
        :error="sortOrderField.hasError()"
        :error-message="sortOrderField.errorMessage"
        :requiredMarker="true"
      />
    </div>
  </div>
  <div class="category-management__category-form--row">
    <div class="category-management__category-form--column-half pr-2">
      <m-datepicker
        v-model="applicationDepositLimitDateField.value"
        v-m-control="applicationDepositLimitDateField"
        :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_deposit_limit_date.label')"
        max-width="none"
        :error="applicationDepositLimitDateField.hasError()"
        :error-message="applicationDepositLimitDateField.errorMessage"
        :requiredMarker="true"
      />
    </div>
    <div class="category-management__category-form--column-half pl-2">
      <m-timepicker
        v-model="applicationDepositLimitTimeField.value"
        v-m-control="applicationDepositLimitTimeField"
        :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_deposit_limit_time.label')"
        max-width="none"
        :error="applicationDepositLimitTimeField.hasError()"
        :error-message="applicationDepositLimitTimeField.errorMessage"
        :requiredMarker="true"
      />
    </div>
  </div>
  <div class="category-management__category-form--row">
    <div class="category-management__category-form--column-half pr-2">
      <m-datepicker
        v-model="evaluationPeriodLimitDateField.value"
        v-m-control="evaluationPeriodLimitDateField"
        :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.evaluation_period_limit_date.label')"
        max-width="none"
        :error="evaluationPeriodLimitDateField.hasError()"
        :error-message="evaluationPeriodLimitDateField.errorMessage"
        :requiredMarker="true"
      />
    </div>
    <div class="category-management__category-form--column-half pl-2">
      <m-timepicker
        v-model="evaluationPeriodLimitTimeField.value"
        v-m-control="evaluationPeriodLimitTimeField"
        :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.evaluation_period_limit_time.label')"
        max-width="none"
        :error="evaluationPeriodLimitTimeField.hasError()"
        :error-message="evaluationPeriodLimitTimeField.errorMessage"
        :requiredMarker="true"
      />
    </div>
  </div>
  <div class="category-management__category-form--row mt-4">
    <div class="category-management__category-form--column">
      <m-checkbox
        v-model="isNewCategoryCheckbox.value"
        v-m-control="isNewCategoryCheckbox"
        :error="isNewCategoryCheckbox.hasError()"
        :error-message="isNewCategoryCheckbox.errorMessage"
      >
        {{ $i18next.t('modules.category-management.category-form.CategoryForm.checkbox.is_new_category.label') }}
      </m-checkbox>
    </div>
  </div>
  <div class="category-management__category-form--row mt-12">
    <div class="category-management__category-form--column">
      <m-checkbox
        v-model="hasApplicationSupportCheckbox.value"
        v-m-control="hasApplicationSupportCheckbox"
        :error="hasApplicationSupportCheckbox.hasError()"
        :error-message="hasApplicationSupportCheckbox.errorMessage"
      >
        {{ $i18next.t('modules.category-management.category-form.CategoryForm.checkbox.has_application_support.label') }}
      </m-checkbox>
    </div>
  </div>
  <transition
    name="fade-fast"
    mode="out-in"
  >
    <template v-if="hasApplicationSupportCheckbox.value">
      <section>
        <div class="category-management__category-form--row mt-2">
          <div class="category-management__category-form--column-half pr-2">
            <m-integerfield
              v-model="applicationSupportCountLowerBoundField.value"
              v-m-control="applicationSupportCountLowerBoundField"
              :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_support_count_lower_bound.label')"
              max-width="none"
              :error="applicationSupportCountLowerBoundField.hasError()"
              :error-message="applicationSupportCountLowerBoundField.errorMessage || applicationSupportCountLowerBoundFieldErrorMessage"
              :requiredMarker="true"
            />
          </div>
          <div class="category-management__category-form--column-half pl-2">
            <m-integerfield
              v-model="applicationSupportCountUpperBoundField.value"
              v-m-control="applicationSupportCountUpperBoundField"
              :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_support_count_upper_bound.label')"
              max-width="none"
              :error="applicationSupportCountUpperBoundField.hasError()"
              :error-message="applicationSupportCountUpperBoundField.errorMessage"
              :requiredMarker="true"
            />
          </div>
        </div>
        <div class="category-management__category-form--row">
          <div class="category-management__category-form--column-half pr-2">
            <m-datepicker
              v-model="applicationSupportDepositLimitDateField.value"
              v-m-control="applicationSupportDepositLimitDateField"
              :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_support_deposit_limit_date.label')"
              max-width="none"
              :error="applicationSupportDepositLimitDateField.hasError()"
              :error-message="applicationSupportDepositLimitDateField.errorMessage"
              :requiredMarker="true"
            />
          </div>
          <div class="category-management__category-form--column-half pl-2">
            <m-timepicker
              v-model="applicationSupportDepositLimitTimeField.value"
              v-m-control="applicationSupportDepositLimitTimeField"
              :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_support_deposit_limit_time.label')"
              max-width="none"
              :error="applicationSupportDepositLimitTimeField.hasError()"
              :error-message="applicationSupportDepositLimitTimeField.errorMessage"
              :requiredMarker="true"
            />
          </div>
        </div>
        <div class="category-management__category-form--row">
          <div class="category-management__category-form--column-half pr-2">
            <m-datepicker
              v-model="applicationSupportSubmitLimitDateField.value"
              v-m-control="applicationSupportSubmitLimitDateField"
              :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_support_submit_limit_date.label')"
              max-width="none"
              :error="applicationSupportSubmitLimitDateField.hasError()"
              :error-message="applicationSupportSubmitLimitDateField.errorMessage"
              :requiredMarker="true"
            />
          </div>
          <div class="category-management__category-form--column-half pl-2">
            <m-timepicker
              v-model="applicationSupportSubmitLimitTimeField.value"
              v-m-control="applicationSupportSubmitLimitTimeField"
              :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_support_submit_limit_time.label')"
              max-width="none"
              :error="applicationSupportSubmitLimitTimeField.hasError()"
              :error-message="applicationSupportSubmitLimitTimeField.errorMessage"
              :requiredMarker="true"
            />
          </div>
        </div>
        <div class="category-management__category-form--row mt-5">
          <div class="category-management__category-form--column">
            <rich-text-editor
              :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_support_creation_dialog_message.label')"
              :ref="REF_APPLICATION_SUPPORT_CREATION_DIALOG_MESSAGE"
              v-model="applicationSupportCreationDialogMessageModel"
            />
          </div>
        </div>
        <div class="category-management__category-form--row mt-5 pb-12">
          <div class="category-management__category-form--column">
            <rich-text-editor
              :label="$i18next.t('modules.category-management.category-form.CategoryForm.field.application_support_creation_email_message.label')"
              :ref="REF_APPLICATION_SUPPORT_CREATION_EMAIL_MESSAGE"
              v-model="applicationSupportCreationEmailMessageModel"
            />
          </div>
        </div>
      </section>
    </template>
  </transition>
</m-form>