import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

import WithRender from './AdminApplicationForms.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MColumnTable,
  MDialog,
  MDialogState,
  MDialogWidth,
  MIcon,
  MIconButton,
  MOption,
  MOptionItem,
  MOptionItemEdit,
  MOptionItemDelete,
  MPagination,
  MPopup,
  MTable,
  MTableHeader,
  MToggleButtons,
  MToggleButton,
  MSearchfield,
  MColumnTextAlign
} from '@ulaval/modul-components'

import EmailStatusRow from '@/modules/global/email/EmailStatusRow'
import ApplicationFormReminderEmailOverlay from '../application-form/ApplicationFormReminderEmailOverlay'
import ApplicationFormDepositDeadlineExtensionOverlay from '../application-form/ApplicationFormDepositDeadlineExtensionOverlay'
import ApplicationFormSupportRow from '@/modules/application-form-support-management/application-form-support/ApplicationFormSupportRow'
import ApplicationFormSupportSubmitReminderEmailOverlay from '../application-form/ApplicationFormSupportSubmitReminderEmailOverlay'
import ApplicationFormAuthorizedUsersOverlay from '../application-form/ApplicationFormAuthorizedUsersOverlay'
import ApplicationFormExportOverlay from '../application-form/ApplicationFormExportOverlay'

import { IYearModel } from '@/modules/global/year/Year.model'
import { IPaginationResultData } from '@/modules/global/utilities/Pagination.model'
import { ApplicationFormStatus, IAdminApplicationFormModel } from '../ApplicationForm.model'
import ApplicationFormService, { IFetchAdminApplicationFormsListQueryParameters, IFetchAdminApplicationFormsListResponse } from '../ApplicationForm.service'
import CONSTANTS from '@/CONSTANTS'
import ApplicationFormMixin from '../ApplicationForm.mixin'
import { EventBus, EVENT_BUS_EVENTS } from '@/modules/global/utilities/Events'
import ROUTES from '@/router/ROUTES'

@WithRender
@Component({
  components: {
    ApplicationFormAuthorizedUsersOverlay,
    ApplicationFormDepositDeadlineExtensionOverlay,
    ApplicationFormExportOverlay,
    ApplicationFormReminderEmailOverlay,
    ApplicationFormSupportSubmitReminderEmailOverlay,
    ApplicationFormSupportRow,
    EmailStatusRow,
    MDialog,
    MIcon,
    MIconButton,
    MOption,
    MOptionItem,
    MOptionItemEdit,
    MOptionItemDelete,
    MPagination,
    MPopup, 
    MTable,
    MTableHeader,
    MToggleButtons,
    MSearchfield
  }
})
export default class AdminApplicationForms extends mixins(ApplicationFormService, ApplicationFormMixin) {
  public formGroup: FormGroup = new FormGroup({
    'search-field': new FormControl()
  })
  private applicationForms: Array<IAdminApplicationFormModel> = []
  private selectedApplicationFormStatus: Array<string> = Object.values(ApplicationFormStatus)
  private paginationData: IPaginationResultData | null = null
  private isLoadingStateActive: boolean = false
  private selectedApplicationFormDepositDeadlineExtension: IAdminApplicationFormModel | null = null
  private selectedApplicationFormToDelete: IAdminApplicationFormModel | null = null
  private selectedApplicationFormAuthorizedUsers: IAdminApplicationFormModel | null = null
  private isDeleteTooltipOpen: boolean = false

  private readonly MDialogState: object = MDialogState
  private readonly MDialogWidth: object = MDialogWidth

  @Prop({ required: true })
  private year!: IYearModel

  public get searchField(): AbstractControl<string> {
    return this.formGroup.getControl<string>('search-field');
  }

  private get statusToggleButtons(): Array<MToggleButton> {
    return Object.values(ApplicationFormStatus).reduce((toggleButtons: Array<MToggleButton>, key: string) => {
      return [
        ...toggleButtons,
        {
          id: key,
          title: this.mixin_applicationForm_computeStatusLabel(key),
          pressed: this.selectedApplicationFormStatus.includes(key) 
        } as MToggleButton
      ]
    }, [])
  }

  private get tableColumns(): Array<MColumnTable> {
    return [
      {
        id: 'candidate',
        title: this.$i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.table.columns.candidate.title'),
        dataProp: 'candidate_full_name_with_username',
        width: '200px'
      },
      {
        id: 'category',
        title: this.$i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.table.columns.category.title'),
        dataProp: 'category_title'
      },
      {
        id: 'status',
        title: this.$i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.table.columns.status.title'),
        dataProp: 'status',
        width: '200px'
      },
      {
        id: 'requester',
        title: this.$i18next.t('modules.application-form-management.application-forms.AdminApplicationForms.table.columns.requester.title'),
        dataProp: 'requester_full_name_with_username',
        width: '200px'
      },
      {
        id: 'options',
        title: '',
        dataProp: 'options',
        textAlign: MColumnTextAlign.Right,
        width: '80px'
      }
    ]
  }

  @Watch('year', { immediate: true })
  private onYearChange(): void {
    this.loadApplicationForms()
  }

  @Watch('selectedApplicationFormStatus')
  private onSelectedApplicationFormStatusChange(): void {
    if (this.paginationData) {
      this.loadApplicationForms(this.paginationData.current_page_index)
    }
  }

  private mounted(): void {
    this.afterApplicationFormUpdated()
    this.afterApplicationFormDeleted()
    this.afterApplicationFormReminderEmailSent()
    this.afterApplicationFormSupportSubmitReminderEmailSent()
  }

  private afterApplicationFormUpdated(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_UPDATED, (updatedApplicationForm: IAdminApplicationFormModel) => {
      if (updatedApplicationForm) {
        const originalApplicationFormIndex: number | undefined = this.applicationForms.findIndex((applicationForm: IAdminApplicationFormModel) => {
          return applicationForm.id === updatedApplicationForm.id
        })

        if (~originalApplicationFormIndex) {
          this.$set(this.applicationForms, originalApplicationFormIndex, updatedApplicationForm)
        }
      }
    })
  }

  private afterApplicationFormDeleted(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_DELETED, (id: string) => {
      if (id) {
        const originalApplicationFormIndex: number | undefined = this.applicationForms.findIndex((applicationForm: IAdminApplicationFormModel) => {
          return applicationForm.id === id
        })

        if (~originalApplicationFormIndex) {
          this.$delete(this.applicationForms, originalApplicationFormIndex)
        }
      }
    })
  }

  private afterApplicationFormReminderEmailSent(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_REMINDER_EMAIL_SENT, () => {
      if (this.paginationData) {
        this.loadApplicationForms(this.paginationData.current_page_index)
      }
    })
  }

  private afterApplicationFormSupportSubmitReminderEmailSent(): void {
    EventBus.$on(EVENT_BUS_EVENTS.APPLICATION_FORM_SUPPORT_SUBMIT_REMINDER_EMAIL_SENT, () => {
      if (this.paginationData) {
        this.loadApplicationForms(this.paginationData.current_page_index)
      }
    })
  }

  private loadApplicationForms(pageIndex: number = 0): void {
    const loadingStateActivationTimeout: any = setTimeout(() => {
      this.isLoadingStateActive = true
    }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

    const query: IFetchAdminApplicationFormsListQueryParameters = {
      year_id: this.year.id,
      query: this.searchField.value,
      status: this.selectedApplicationFormStatus,
      page: pageIndex
    }

    this.service_applicationFormService_fetchAdminApplicationFormList(query)  
      .then((responseData: IFetchAdminApplicationFormsListResponse) => {
        this.applicationForms = responseData.application_forms || []
        this.paginationData = responseData.pagination || null
      }).catch(() => {
        this.applicationForms = []
        this.paginationData = null
      }).finally(() => {
        clearTimeout(loadingStateActivationTimeout)
        this.isLoadingStateActive = false
      })
  }

  private deleteApplicationForm(): void {
    if (this.selectedApplicationFormToDelete) {
      const loadingStateActivationTimeout: any = setTimeout(() => {
        this.isLoadingStateActive = true
      }, CONSTANTS.DEFAULT_LOADING_STATE_TIMEOUT_VALUE)

      this.service_applicationFormService_deleteApplicationFormAdmin(this.selectedApplicationFormToDelete.id)  
        .finally(() => {
          clearTimeout(loadingStateActivationTimeout)
          this.isLoadingStateActive = false
          this.resetApplicationFormToDelete()
        })
    }
  }

  private onSearchFieldChange(): void {
    this.loadApplicationForms(0)
  }

  private onPageChange(newIndex: number): void {
    this.loadApplicationForms(newIndex - 1)
  }

  private onToggleButtonClick(toggleButton: MToggleButton): void {
    if (toggleButton.pressed) {
      const statusIndex: number | undefined = this.selectedApplicationFormStatus.indexOf(toggleButton.id)

      if (!~statusIndex) {
        this.$set(this.selectedApplicationFormStatus, this.selectedApplicationFormStatus.length, toggleButton.id)
      }
    } else {
      const statusIndex: number | undefined = this.selectedApplicationFormStatus.indexOf(toggleButton.id)

      if (~statusIndex) {
        this.$delete(this.selectedApplicationFormStatus, statusIndex)
      }
    }
  }

  private onShowApplicationFormIconClick(event: Event, applicationForm: IAdminApplicationFormModel): void {
    this.mixin_router_navigate({
      name: ROUTES.ADMIN_APPLICATION_FORM,
      params: {
        idApplicationForm: applicationForm.id
      }
    }, event)
  }

  private onDisplayDepositDeadlineExtensionIconClick(applicationForm: IAdminApplicationFormModel): void {
    this.selectedApplicationFormDepositDeadlineExtension = applicationForm || null
  }

  private afterDisplayDepositDeadlineExtensionOverlayClose(): void {
    this.selectedApplicationFormDepositDeadlineExtension = null
  }

  private triggerDeleteConfirmationDialog(applicationForm: IAdminApplicationFormModel): void {
    this.selectedApplicationFormToDelete = applicationForm
  }

  private resetApplicationFormToDelete(): void {
    this.selectedApplicationFormToDelete = null
  }

  private onDisplayAuthorizedUsersIconClick(applicationForm: IAdminApplicationFormModel): void {
    this.selectedApplicationFormAuthorizedUsers = applicationForm || null
  }

  private afterAuthorizedUsersOverlayClose(): void {
    this.loadApplicationForms(this.paginationData ? this.paginationData.current_page_index : 0)
    this.selectedApplicationFormAuthorizedUsers = null
  }
}
