import Component from "vue-class-component";

import { AxiosError, AxiosResponse } from "axios";

import { IFormAnswerFileModel, IFormModel } from "../form-management/Form.model";
import FormTemplateService from "../form-template-management/FormTemplate.service";
import { IInternalFormTemplateModel } from "../form-template-management/FormTemplate.model";
import { IFormAnswerForm } from "../form-management/Form.service";
import { IFileUploadModel } from "../global/file/File.model";
import { IAdminApplicationFormSupportModel, IApplicationFormSupportModel } from "./ApplicationFormSupport.model";
import { IPaginationQueryParameters } from "../global/utilities/Base.service";
import { IPaginatedResponse } from "../global/utilities/Pagination.model";
import { EVENT_BUS_EVENTS } from "../global/utilities/Events";
import { ICategoryModel } from "../category-management/Category.model";

export interface IFetchApplicationFormSupportResponse {
  application_form_support: IApplicationFormSupportModel;
  form: IFormModel;
  internal_form_template: IInternalFormTemplateModel;
}


export interface IFetchAdminApplicationFormSupportsListQueryParameters extends IPaginationQueryParameters {
  year_id: string;
  query?: string;
  status: Array<string>;
}

export interface IFetchAdminApplicationFormSupportsListResponse extends IPaginatedResponse {
  application_form_supports: Array<IAdminApplicationFormSupportModel>;
}

export interface ISendApplicationFormSupportReminderEmailForm {
  categories_id: Array<string>;
}

@Component
export default class ApplicationFormSupportService extends FormTemplateService {
  protected service_applicationFormSupportService_fetchApplicationFormSupport(token: string): Promise<IFetchApplicationFormSupportResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/application-form-supports/${token}`)
        .then((response: AxiosResponse) => resolve(
          {
            application_form_support: response.data.data.application_form_support,
            form: response.data.data.form,
            internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.form.form_template)
          }
        ))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormSupportService_fetchAdminApplicationFormSupport(id: string): Promise<IFetchApplicationFormSupportResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.get(`/api/admin/application-form-supports/${id}`)
        .then((response: AxiosResponse) => resolve(
          {
            application_form_support: response.data.data.application_form_support,
            form: response.data.data.form,
            internal_form_template: this.service_formTemplateService_mapFormTemplateToInternalFormTemplate(response.data.data.form.form_template)
          }
        ))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormSupportService_submitApplicationFormSupport(token: string, answers: Array<IFormAnswerForm>): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(
        `/api/application-form-supports/${token}/submit`, 
        this.service_applicationFormSupportService_generateAnswersFormData(answers), 
        {
          headers: this.service_baseService_multipartFormDataHeader()
        }
      ).then(() => {
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }

  private service_applicationFormSupportService_generateAnswersFormData(answers: Array<IFormAnswerForm>): FormData {
    const data: FormData = new FormData()

    answers.forEach((answerForm: any, index: number) => {
      for (const key in answerForm) {
        const answerValue: any = answerForm[key]

        if (
          answerValue != undefined && 
          answerValue != null &&
          key != 'files'
        ) {
          data.append(`answers[${index}].${key}`, answerValue)
        }
      }

      if (answerForm.files) {
        answerForm.files.forEach((uploadedFile: IFileUploadModel<IFormAnswerFileModel>, fileIndex: number) => {
          if (uploadedFile.server_file) {
            data.set(`answers[${index}].form_answer_files[${fileIndex}].id`, uploadedFile.server_file.id)
          } else if (uploadedFile && uploadedFile.file) {
            data.append(`answers[${index}].form_answer_files[${fileIndex}].file`, uploadedFile.file.file)
          }
        })
      }
    })

    return data
  }

  protected service_applicationFormSupportService_fetchAdminApplicationFormList(query: IFetchAdminApplicationFormSupportsListQueryParameters): Promise<IFetchAdminApplicationFormSupportsListResponse> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery(query)

      this.$axios.get(`/api/admin/application-form-supports?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data))
        .catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormSupportService_sendApplicationFormSupportReminderEmails(payload: ISendApplicationFormSupportReminderEmailForm): Promise<IFetchAdminApplicationFormSupportsListResponse> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.post(`/api/admin/application-form-supports/reminder-emails`, payload)
        .then((response: AxiosResponse) => {
          this.service_baseService_emitEventBusEvent(EVENT_BUS_EVENTS.APPLICATION_FORM_SUPPORT_REMINDER_EMAIL_SENT, null)

          return resolve(response.data.data)
        }).catch((error: AxiosError) => reject(error))
    })
  }

  protected service_applicationFormSupportService_saveAdmin(id: string, answers: Array<IFormAnswerForm>): Promise<void> {
    return new Promise((resolve: any, reject: any): void => {
      this.$axios.put(
        `/api/admin/application-form-supports/${id}`, 
        this.service_applicationFormSupportService_generateAnswersFormData(answers), 
        {
          headers: this.service_baseService_multipartFormDataHeader()
        }
      ).then(() => {
          resolve()
        }).catch((error: AxiosError) => reject(error))
    })
  }
  
  protected service_applicationFormSupportService_fetchAvailableCategoriesSubmit(yearId: string): Promise<Array<ICategoryModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery({
        year_id: yearId
      })

      this.$axios.get(`/api/admin/application-form-supports/available-categories:submit?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.categories))
        .catch((error: AxiosError) => reject(error))
    })
  }
  
  protected service_applicationFormSupportService_fetchAvailableCategoriesDeposit(yearId: string): Promise<Array<ICategoryModel>> {
    return new Promise((resolve: any, reject: any): void => {
      const queryString: string = this.service_baseService_generateFilterQuery({
        year_id: yearId
      })

      this.$axios.get(`/api/admin/application-form-supports/available-categories:deposit?${queryString}`)
        .then((response: AxiosResponse) => resolve(response.data.data.categories))
        .catch((error: AxiosError) => reject(error))
    })
  }
}