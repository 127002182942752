<m-form
  :form-group="formGroup"
  class="user-management__user-form"
>
  <template v-if="!hideUserInformationsForm">
    <div class="user-management__user-form--row">
      <div class="user-management__user-form--column">
        <m-searchfield
          v-model="idulSearchField.value"
          v-m-control="idulSearchField"
          :label="idulSearchBarLabel || $i18next.t('modules.user-management.user-form.UserForm.field.idul_search.label')"
          :helper-message="$i18next.t('modules.user-management.user-form.UserForm.field.idul_search.helper')"
          max-width="none"
          :error="idulSearchField.hasError()"
          :error-message="idulSearchField.errorMessage || idulSearchFieldErrorMessage"
          :waiting="isLdapInformationsSearchLoadingStateActive"
          :required-marker="true"
          :placeholder="idulSearchBarPlaceholder"
          :label-up="!mixin_string_isEmpty(idulSearchBarPlaceholder)"
          @search="fetchLdapUserInformations"
          @empty-field="clearUserInformations"
        />
      </div>
    </div>
    <div class="user-management__user-form--row mt-6">
      <div class="user-management__user-form--column-half pr-2">
        <m-textfield
          v-model="firstNameField.value"
          v-m-control="firstNameField"
          :disabled="true"
          :label="$i18next.t('modules.user-management.user-form.UserForm.field.first_name.label')"
          max-width="none"
          :required-marker="true"
          :error="firstNameField.hasError()"
          :error-message="firstNameField.errorMessage"
        />
      </div>
      <div class="user-management__user-form--column-half pl-2">
        <m-textfield
          v-model="lastNameField.value"
          v-m-control="lastNameField"
          :disabled="true"
          :label="$i18next.t('modules.user-management.user-form.UserForm.field.last_name.label')"
          max-width="none"
          :required-marker="true"
          :error="lastNameField.hasError()"
          :error-message="lastNameField.errorMessage"
        />
      </div>
    </div>
    <div class="user-management__user-form--row">
      <div class="user-management__user-form--column-half pr-2">
        <m-textfield
          v-model="usernameField.value"
          v-m-control="usernameField"
          :disabled="true"
          :label="$i18next.t('modules.user-management.user-form.UserForm.field.username.label')"
          max-width="none"
          :required-marker="true"
          :error="usernameField.hasError()"
          :error-message="usernameField.errorMessage"
        />
      </div>
      <div class="user-management__user-form--column-half pl-2">
        <m-textfield
          v-model="emailField.value"
          v-m-control="emailField"
          :disabled="true"
          :label="$i18next.t('modules.user-management.user-form.UserForm.field.email.label')"
          max-width="none"
          :required-marker="true"
          :error="emailField.hasError()"
          :error-message="emailField.errorMessage"
        />
      </div>
    </div>
  </template>
  <template v-if="withRoleSelection">
    <div class="user-management__user-form--row">
      <div class="user-management__user-form--column">
        <m-multi-select
          v-model="selectedRoles"
          :options="rolesSelectOptions"
          :label="$i18next.t('modules.user-management.user-form.UserForm.field.role_select.label')"
          max-width="none"
          :link-select-all="true"
          :waiting="isRoleLoadingStateActive"
          :defaultChipCharsTrunk="100"
        />
      </div>
    </div>
  </template>
</m-form>