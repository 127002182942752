import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import { AxiosError } from 'axios'

import WithRender from './ApplicationFormExportOverlay.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MButton,
  MButtonSkin,
  MForm,
  MMessage,
  MMessageSkin,
  MMessageState,
  MMultiSelect,
  MOverlay,
  RequiredValidator
} from '@ulaval/modul-components'

import { IYearModel } from '@/modules/global/year/Year.model'
import ApplicationFormService from '../ApplicationForm.service'
import { ApplicationFormStatus } from '../ApplicationForm.model'
import ApplicationFormMixin from '../ApplicationForm.mixin'

const REF_DOWNLOAD_LINK: string = 'ref-download-link'

@WithRender
@Component({
  components: {
    MButton,
    MForm,
    MMessage,
    MMultiSelect,
    MOverlay,
  }
})
export default class ApplicationFormExportOverlay extends mixins(ApplicationFormService, ApplicationFormMixin) {
  public formGroup: FormGroup = new FormGroup({
    'status-multi-select': new FormControl<Array<string>>([RequiredValidator()])
  })
  private isLoadingStateActive: boolean = false
  private isOverlayOpen: boolean = false
  private showSuccessMessage: boolean = false
  private selectedStatus: Array<string> = []

  private readonly MButtonSkin: object = MButtonSkin
  private readonly MMessageState: object = MMessageState
  private readonly MMessageSkin: object = MMessageSkin
  private readonly REF_DOWNLOAD_LINK: string = REF_DOWNLOAD_LINK

  @Ref(REF_DOWNLOAD_LINK)
  protected downloadLinkHtmlElement!: HTMLElement

  @Prop({ required: true })
  private year!: IYearModel

  public get statusMultiSelect(): AbstractControl<Array<string>> {
    return this.formGroup.getControl('status-multi-select');
  }

  private get statusSelectOptions(): Array<any> {
    return Object.values(ApplicationFormStatus)
  }

  private closeOverlay(): void {
    this.isOverlayOpen = false
  }

  private onTriggerButtonClick(): void {
    this.isOverlayOpen = true
  }

  private async onSaveButtonClick(): Promise<void> {
    this.statusMultiSelect.value = this.selectedStatus

    await this.formGroup.submit()

    if (!this.formGroup.hasErrorDeep()) {
      this.downloadFile()
    }
  }

  private downloadFile(): void {
    this.isLoadingStateActive = true

    this.service_applicationFormService_adminDownloadExportFile({
        status: this.selectedStatus,
        year_id: this.year.id
      }).then((res: any) => {
          if (this.downloadLinkHtmlElement) {
            this.downloadLinkHtmlElement.setAttribute('href', URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' })))
            this.downloadLinkHtmlElement.setAttribute('download', 'dossiers-candidature.xlsx')
            this.downloadLinkHtmlElement.click()
      
            this.downloadLinkHtmlElement.removeAttribute('link')
            this.downloadLinkHtmlElement.removeAttribute('download')
          }

          this.showSuccessMessage = true
      }).catch((error: AxiosError) => {
        this.mixin_router_redirectRequestError(error)
      }).finally(() => {
        this.isLoadingStateActive = false
      })
  }

  private afterOverlayClose(): void {
    this.isLoadingStateActive = false
    this.showSuccessMessage = false
    this.selectedStatus = []

    this.formGroup.reset()
  }
}
