import Component, { mixins } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import WithRender from './ApplicationFormForm.html'

import { 
  AbstractControl,
  FormControl,
  FormGroup,
  MAdd,
  MCheckbox,
  MDropdown,
  MDropdownItem,
  MForm,
  MMessage,
  MMessageState,
  RequiredValidator,
} from '@ulaval/modul-components'

import UserForm, { IUserFormPayloadData } from '@/modules/user-management/user-form/UserForm'
import ApplicationFormSupportForm, { IApplicationFormSupportFormPayloadData } from '@/modules/application-form-support-management/application-form-support-form/ApplicationFormSupportForm'

import { IYearModel } from '@/modules/global/year/Year.model'
import { ICategoryModel } from '@/modules/category-management/Category.model'
import ApplicationFormService from '../ApplicationForm.service'
import { STORE_GETTERS } from '@/store'

const USER_FORM_REF: string = 'user-form'
const APPLICATION_FORM_SUPPORT_FORM_REF: string = 'application-form-support-form'

export interface IApplicationFormFormPayloadData {
  category_id: string;
  candidate_idul: string;
  application_form_supports?: Array<IApplicationFormSupportFormPayloadData>;
}

@WithRender
@Component({
  components: {
    ApplicationFormSupportForm, 
    MAdd,
    MCheckbox,
    MDropdown,
    MDropdownItem,
    MForm,
    MMessage,
    UserForm
  }
})
export default class ApplicationFormForm extends mixins(ApplicationFormService) {
  public formGroup: FormGroup = new FormGroup({
    categoryId: new FormControl<string>([RequiredValidator()])
  })
  private categories: Array<ICategoryModel> = []
  private isCategoryLoadingStateActive: boolean = false
  private applicationFormSupportForms: Array<number> = []

  private readonly USER_FORM_REF: string = USER_FORM_REF
  private readonly APPLICATION_FORM_SUPPORT_FORM_REF: string = APPLICATION_FORM_SUPPORT_FORM_REF
  private readonly MMessageState: object = MMessageState

  @Prop({ required: true })
  private year!: IYearModel

  @Ref(USER_FORM_REF)
  private userFormComponent!: UserForm

  @Ref(APPLICATION_FORM_SUPPORT_FORM_REF)
  private applicationFormSupportFormComponents!: Array<ApplicationFormSupportForm>

  private get categoryDropdown(): AbstractControl<string> {
    return this.formGroup.getControl<string>('categoryId');
  }

  private get categoriesMappedById(): { [key: string]: ICategoryModel } {
    return this.categories.reduce((mappedCategories: { [key: string]: ICategoryModel }, category: ICategoryModel) => {
      mappedCategories[category.id] = category
      return mappedCategories
    }, {})
  }

  private get selectedCategory(): ICategoryModel | null {
    if (!this.categoryDropdown.value) {
      return null
    }

    return this.categoriesMappedById[this.categoryDropdown.value] || null
  }

  @Watch('categoryDropdown.value')
  private onSelectedCategoryChange(): void {
    this.applicationFormSupportForms = []
  }

  private created(): void {
    this.loadAvailableCategories()
  }

  private loadAvailableCategories(): void {
    this.isCategoryLoadingStateActive = true

    this.service_applicationFormService_fetchAvailableCategories(this.year.id)
      .then((response: Array<ICategoryModel>) => {
        this.categories = response || []
      }).catch(() => {
        this.categories = []
      }).finally(() => {
        this.isCategoryLoadingStateActive = false
        this.preselectCategory()
      })
  }

  private preselectCategory(): void {
    if (this.$route.query.category) {
      const isCategoryAvailable: boolean = !!this.categories.find((category: ICategoryModel) => category.id === this.$route.query.category)

      if (isCategoryAvailable) {
        this.categoryDropdown.value = this.$route.query.category as string
      }
    }
  }

  public async getFormPayload(): Promise<IApplicationFormFormPayloadData | null> {
    await this.formGroup.submit()
    const candidateIdul: string | null = await this.getCandidateIdul()
    const applicationFormSupports: Array<IApplicationFormSupportFormPayloadData> = []
    let hasApplicationFormSupportError: boolean = false

    if (this.applicationFormSupportFormComponents) {
      for (const applicationFormSupportForm of this.applicationFormSupportFormComponents) {
        const applicationFormSupportFormPayload: IApplicationFormSupportFormPayloadData | null = await applicationFormSupportForm.getFormPayload()

        if (applicationFormSupportFormPayload) {
          applicationFormSupports.push(applicationFormSupportFormPayload)
        } else {
          hasApplicationFormSupportError = true
        }
      }
    }

    if (this.formGroup.hasErrorDeep() || !candidateIdul || hasApplicationFormSupportError) {
      return null
    }

    const formPayloadData: IApplicationFormFormPayloadData = {
      category_id: this.categoryDropdown.value,
      candidate_idul: candidateIdul,
      application_form_supports: applicationFormSupports
    }

    return formPayloadData
  }

  private async getCandidateIdul(): Promise<string | null> {
    if (this.userFormComponent) {
      const userFormPayload: IUserFormPayloadData | null = await this.userFormComponent.getFormPayload()

      if (userFormPayload && userFormPayload.idul) {
        return userFormPayload.idul
      }
    } 

    return null
  }

  private increaseApplicationFormSupportFormCount(key: number): void {
    this.$set(this.applicationFormSupportForms, this.applicationFormSupportForms.length, key + 1)
  }

  private decreaseApplicationFormSupportFormCount(index: number): void {
    this.$delete(this.applicationFormSupportForms, index)
  }
}
